import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { OrgSupportTicket, SupportTicket, SupportTicketChatItem } from '@app/_alchemint/alchemint_dm';
import { AuthenticationService } from '@app/_services';
import { WebApiInterfaceService } from '@app/_services/alchemint.webapiinterface.service';
import { DateTimeService } from '@app/_services/date-time.service.spec';
import { NotificationService } from '@app/_services/notification.service';
import { AlchemintSharedService } from '@app/alchemint-shared.service';
import { HelpDeskService } from '@app/help-desk.service';
import { stdTransformDependencies } from 'mathjs';
import { firstValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-support-chat',
  templateUrl: './support-chat.component.html',
  styleUrls: ['./support-chat.component.scss'],
  providers: [HelpDeskService, DateTimeService]
})
export class SupportChatComponent implements AfterViewInit {

  _supportTicket: SupportTicket;

  _masterHelpDeskMode: boolean;

  @Input() public set masterHelpDeskMode(val: boolean) {
    this._masterHelpDeskMode = val;
  }
  public get masterHelpDeskMode(): boolean {
    return this._masterHelpDeskMode;
  }

  @Input() public set supportTicket(val: SupportTicket) {
    if (this._supportTicket != val) {
      this._supportTicket = val

      if (this._supportTicket)
      {
        this.loadConversation(val.id);

        if (this.isConnected === true) {
          this.disconnectAndReconnectSignalR();
        }
        else {
          this.connectToSignalR();
        }
  
      }
    }
  }

  async disconnectAndReconnectSignalR() {
    await this.dissconnectSignalR();
    this.connectToSignalR();
  }




  public get supportTicket(): SupportTicket {
    return this._supportTicket;
  }

  _uniqueUserId: string;
  @Input() public set uniqueUserId(val: string) {
    this._uniqueUserId = val;
  }
  public get uniqueUserId(): string {
    return this._uniqueUserId;
  }

  constructor(
    private helpDeskService: HelpDeskService,
    private authenticationService: AuthenticationService,
    private alchemintSharedService: AlchemintSharedService,
    private webApiInterfaceService: WebApiInterfaceService,
    private notificationService: NotificationService,
    public dateTimeService: DateTimeService,
    public router: Router
  ) {


  }


  lastChatItem: any;
  subNotifications: Subscription;
  ngAfterViewInit(): void {
    this.clientID = this.authenticationService.clientIdFromToken;

    this.getAllSupportTicketsForUser();

    if (this.subNotifications) {
      this.subNotifications.unsubscribe();
    }



    // Need to fix the bug why this is firing twice.

    this.subNotifications = this.notificationService.getSupportChatNotifications().subscribe(notification => {
      // Workaround for double firing of the same message. Need to fix
      if (this.lastChatItem !== notification.obj.message) {

        this.lastChatItem = notification.obj.message;

        var supp: SupportTicketChatItem = notification.obj;

        this.parseSupportChatItem(supp);
        this.messages.push(supp);
        

      }
      else {
        console.log('Duplicate message');
      }


    });

    this.notificationService.getConnectionStatus().subscribe(status => {
      this.isConnected = status;

      if (this.isConnected === true) {
        if (this.pendingSendMessage) {
          var msg = this.pendingSendMessage;
          this.sendSupportChatItemMessageToAlchemed(msg);
          this.pendingSendMessage = null;
        }
      }

    });




  }

  parseSupportChatItem(item: SupportTicketChatItem) {
    if (item.message.startsWith('Link: ')) {
      var routeString = item.message.replace('Link: ', '');

      // Extracting query parameters from the string
      var queryParams: Record<string, string> = this.extractQueryParams(routeString);

      // Getting the route path (in this case, 'login')
      const routePath: string = routeString.split('?')[0];

      //var msgWithLink = `Goto : <a href="${routeString}">Link</a>`;

      item.routeLink = routeString;
      item.message = '';

    }
  }



  gotoSentLink(routerLink: string) {

    // Extracting query parameters from the string
    var queryParams: Record<string, string> = this.extractQueryParams(routerLink);

    // Getting the route path (in this case, 'login')
    const routePath: string = routerLink.split('?')[0];

    //var msgWithLink = `Goto : <a href="${this.routeInstruction}">Link</a>`;

    if (routerLink.includes('login')) {
      this.authenticationService.logout(false, true, true, true, true, true, false);
      setTimeout(() => {
        // this.router.navigate([routePath], { queryParams: queryParams });
        this.reloadComponent(routePath, queryParams);
      },
        3000)
    }
    else {
      // this.router.navigate([routePath], { queryParams: queryParams });
      this.reloadComponent(routePath, queryParams);
    }


  }

// Helper method to reload the component
private reloadComponent(routePath: string, queryParams: Record<string, string>): void {
  // Navigate to a temporary route and then back to force reload
  this.router.navigateByUrl('/temp', { skipLocationChange: true }).then(() => {
    this.router.navigate([routePath], { queryParams: queryParams });
  });
}
  extractQueryParams(url: string): Record<string, string> {
    const queryParams: Record<string, string> = {};
    const urlParts = url.split('?');
    if (urlParts.length > 1) {
      const queryString = urlParts[1];
      const pairs = queryString.split('&');
      pairs.forEach(pair => {
        const [key, value] = pair.split('=');
        if (key && value) {
          queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
        }
      });
    }
    return queryParams;
  }


  toggleConnectionState() {
    if (this.isConnected === true) {
      alert('Disconnect');
    }
    else {
      this.connectToSignalR();
    }
  }


  connectToSignalR() {
    if (this.supportTicket) {
      this.notificationService.initialize(this.uniqueUserId, this.supportTicket.id);
    }
    else {
      this.alchemintSharedService.messageDialogSync("You must select a support ticket to connect to the help desk server");
    }

    //this.notificationService.GetConnectedUsers();
  }

  async dissconnectSignalR() {
    await this.notificationService.closeConnection();
  }


  public clientID: string;

  historyVisible: boolean = false;
  isChatOpen = true;
  newMessage = '';
  messages: SupportTicketChatItem[] = [];


  @Output() requestCloseChat = new EventEmitter();
  closeChat() {
    this.requestCloseChat?.emit();
  }

  async sendMessage() {

    // if (this.isConnected === false)
    // {
    //  this.alchemintSharedService.messageDialogSync("You are not connected to the help desk server. Please connect.");
    //  return;
    // }

    if (this.masterHelpDeskMode === true) {
      await this.sendMessageToClientFromAlchemedSupport();
    }
    else {
      await this.sendMessageToAlchemedSupport();
    }
  }

  async sendMessageToClientFromAlchemedSupport() {

    if (this.supportTicket) {

      var supportTicketId = this.supportTicket.id;

      var chatitem = new SupportTicketChatItem();
      chatitem.message = this.newMessage;
      chatitem.isClientMessage = false;
      chatitem.supportTicketId = supportTicketId;
      var createdChatItem = await firstValueFrom(this.helpDeskService.clientSupporTicketChatItem(chatitem));
      this.messages.push(createdChatItem);

      this.sendSupportChatItemMessageFromAlchemedToUser(this.supportTicket.loggedByUserId, createdChatItem)

      this.newMessage = '';
    }
    else {
      this.alchemintSharedService.messageDialogSync("You must select a support ticket to send a message to the client");
    }

  }

  slaResponseTimeMinutes: number = 60;

  async sendMessageToAlchemedSupport() {

    if (!this.clientID) {
      this.alchemintSharedService.messageDialogSync("You must be logged in to send a message");
      return;
    }

    if (this.newMessage.trim()) {

      var supportTicketId: string;
      if (this.messages.length == 0) {
        var st: SupportTicket = new SupportTicket();
        st.closed = false;
        st.description = this.newMessage;
        st.loggedDate = new Date();
        st.customerId = this.clientID;
        st.loggedByUserId = this.authenticationService.userIdFromToken;
        st.loggedBy = this.authenticationService.userNameFromToken;

        var stRet = await firstValueFrom(this.helpDeskService.clientAddTicket(st));


        if ((this.orgSupportItems?.length > 0) === false) {
          this.orgSupportItems = [];
        }
        this.orgSupportItems.push(stRet.orgSupportTicket);
        this.supportTicket = stRet.supportTicket;
        supportTicketId = stRet.supportTicket.id;
        this.messages.push(stRet.supportTicketChatItem);




        if (this.isConnected === true) {
          this.sendSupportChatItemMessageToAlchemed(stRet.supportTicketChatItem);
        }
        else {
          this.pendingSendMessage = stRet.supportTicketChatItem;
          this.connectToSignalR();
        }

      }
      else {
        supportTicketId = this.messages[0].supportTicketId;

        var chatitem = new SupportTicketChatItem();
        chatitem.message = this.newMessage;
        chatitem.isClientMessage = true;
        chatitem.supportTicketId = supportTicketId;
        var createdChatItem = await firstValueFrom(this.helpDeskService.clientSupporTicketChatItem(chatitem));
        this.messages.push(createdChatItem);
        this.sendSupportChatItemMessageToAlchemed(createdChatItem);
      }





      this.newMessage = '';

    }
  }

  pendingSendMessage: SupportTicketChatItem = null;


  loadHistoricalSupportTicket(ticketId: string) {
    var sub = this.helpDeskService.getSupportTicket(ticketId).subscribe
      (ticket => {

        this.supportTicket = ticket;
      },
        error => {
          this.alchemintSharedService.oppenErrDialogForObjectErr(error);
          sub.unsubscribe();
        },
        () => {
          sub.unsubscribe();
        }
      );
  }



  public showDetail: boolean = false;

  async loadConversation(supportTicketId: string) {
    try {


      this.helpDeskService.getClientSupporTicketChatItems(supportTicketId).subscribe
        (chatItems => {
          if (chatItems) {
            
            chatItems.forEach(supp =>
            {
              this.parseSupportChatItem(supp);
            });
            
            this.messages = chatItems.reverse();

            this.messages.forEach(supp => 
            {
              supp.addedDate = new Date(Date.parse(supp.addedDate + 'Z')) 
            });


            if ((this.messages?.length === 1) && (this.masterHelpDeskMode === false)) {
              // If this is first message add that we will get back within certain time
              var autoMessage = new SupportTicketChatItem();
              autoMessage.message = "Thank you for your message. We will get back to you within " + this.slaResponseTimeMinutes + " minutes"
              this.messages.push(autoMessage);
            }

            this.groupMessagesByDate();

          }
          else {
            this.messages = [];
          }

        });

      // var chatItems: SupportTicketChatItem[] = await firstValueFrom(this.helpDeskService.getClientSupporTicketChatItems(supportTicketId))

      // if (chatItems)
      // {
      //   this.messages = chatItems.reverse();
      // }
      // else
      // {
      //   this.messages = [];
      // }




    } catch (error) {
      this.alchemintSharedService.oppenErrDialogForObjectErr(error);
    }

  }

  public orgSupportItems: OrgSupportTicket[] = [];
  async getAllSupportTicketsForUser() {
    try {
      var orgSupportItems: OrgSupportTicket[] = await firstValueFrom(this.webApiInterfaceService.getEntities<OrgSupportTicket>(new OrgSupportTicket()));

      orgSupportItems = orgSupportItems?.filter(x => x.question?.length > 0);

      orgSupportItems = orgSupportItems.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

      this.orgSupportItems = orgSupportItems;

    } catch (error) {
      this.alchemintSharedService.oppenErrDialogForObjectErr(error);
    }

  }




  notifications: any[] = [];



  isConnected: boolean;

  ngOnInit(): void {
    // this.notificationService.getNotifications().subscribe(notification => {

    //   this.notifications.push(notification);
    // });



  }


  // public sendTestMessage(): void {
  //   this.notificationService.sendTestMessage();
  // }

  // public sendSupportChatItemMessage(): void{
  //   this.notificationService.sendSupportChatItemMessage(this.uniqueUserId, JSON.stringify( this.messages[0]));
  // }

  private masterSupportUserId = 'Alchemed-Support-1';
  public sendSupportChatItemMessageToAlchemed(supportTicketChatItem: SupportTicketChatItem): void {

    this.notificationService.sendSupportChatItemMessage(this.masterSupportUserId, supportTicketChatItem);
  }

  public sendSupportChatItemMessageFromAlchemedToUser(userId: string, supportTicketChatItem: SupportTicketChatItem): void {
    this.notificationService.sendSupportChatItemMessage(userId, supportTicketChatItem);
  }




  // public sendHelloWorld(): void {
  //   this.notificationService.sendHelloWorld();
  // }

  public GetConnectedUsers(): void {
    this.notificationService.GetConnectedUsers();
  }
  groupedMessages: { date: Date, messages: any[] }[] = [];
  groupMessagesByDate() {
    const groups = this.messages.reduce((groups, message) => {
      const date = message.addedDate.toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});

    this.groupedMessages = Object.keys(groups).map(date => ({
      date: new Date(date),
      messages: groups[date]
    }));
  }
}
