<style>
  /* .alc_update_div {
        border: solid;
        border-color: white;
        border-radius: 1em;
        margin-bottom: 2em;
        padding: 2em;
        color: white;
    } */
  a,
  a:hover {
    color: #212529;
  }

  a:hover {
    text-decoration: none;
  }
  .nav-link {
    color: white;
  }
  .dropdown {
    color: white ;
  }

  .centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1cm;
  margin: 1em;
}
</style>




<app-splash-screen *ngIf="shouldShowSplashScreen"></app-splash-screen>

<app-training-recorder [loadedModuleName]="routerOutletLoadedComponentName">

</app-training-recorder>




<div class="centered-content" *ngIf="(appUpdateService.currentVersion !== appUpdateService.serverVersion) && (appUpdateService.updating===false) && (appUpdateService.versionCheckComplete===true)" >
  <button class="btn btn-secondary" (click)="forceUpdate()" title="Your app needs to be updated. Please press hear to update.">
    Update Required - Please press here to update
  </button>
  
</div>



<!-- <app-input-popup (onChange)="doSearch($event)"  (onItemSelection)="onSelection($event)" [dropDownOptions]="options"></app-input-popup> -->
<!-- <app-account-search></app-account-search> -->

<!-- <nav class="navbar navbar-inverse" id="navigationBar" [hidden]="fullScreenEditMode"> -->
  <!-- navbar-inverse -->
  <!-- class="navbar" -->
  <nav [class]="mustHideTitleBar ? '' : 'navbar'" id="navigationBar" [hidden]="fullScreenEditMode" >

  <!-- class="main-content" -->
  <div class="container-fluid" >


    <div [hidden]="mustHideTitleBar === true">




      <img *ngIf="aiIsHappening===true" src="assets/AIAssist-transparent.png" class="ai-image"  />

      <img src="assets/Alchemint.png" loading="lazy" *ngIf="isAlchemintHosted" (click)="isExpanded = !isExpanded" class="applogo">

      <img class="applogo" width="50%" height="auto" src="assets/Alchemednew.png" loading="lazy" *ngIf="(!isAlchemintHosted) && (isExternalRequest===false) && (isIntegratedBillingEnabled===false) && (IsRetinaAssistLicense===false)" (click)="reloadPageOrStopRecording()"  alt="Alchemed Solutions" title="Click to Reload. Current Version: {{appUpdateService.currentVersion}}. Server Version {{appUpdateService.serverVersion}}."/>


      <img  *ngIf="IsRetinaAssistLicense" class="applogo" style="width:30vw; height: auto; max-width:15cm;" src="assets/Retina Assist Pro.png" loading="lazy" (click)="reloadPageOrStopRecording()"  alt="Alchemed Solutions" title="Click to Reload. Current Version: {{appUpdateService.currentVersion}}. Server Version {{appUpdateService.serverVersion}}."/>





      <!-- <img class="applogo" width="50%" height="auto" src="assets/HB Logo Primary.jpg" loading="lazy" *ngIf="(!isAlchemintHosted) && (isExternalRequest===false) && (winAppIntegratedMode===false) && (isConferenceOrg===false)" (click)="reloadPageOrStopRecording()"  alt="Alchemed Solutions" title="Click to Reload"/> -->

      <!-- <img class="applogo" width="40%" height="auto" src="assets/AlchemedPweredByiHealth.png" loading="lazy" *ngIf="(!isAlchemintHosted) && (isExternalRequest===false) && (winAppIntegratedMode===false) && (isConferenceOrg===true)" (click)="reloadPageOrStopRecording()"  alt="Alchemed Solutions" title="Click to Reload"/> -->
      
      
      <img style="width:30vw; height: auto; max-width:15cm;" class="applogo" src="assets/iHealth_Alch_logo_v2.png" loading="lazy" *ngIf="(!isAlchemintHosted) && (isExternalRequest===false) && (winAppIntegratedMode===false) && (isIntegratedBillingEnabled===true) && (IsRetinaAssistLicense===false)" (click)="reloadPageOrStopRecording()"  alt="Alchemed Solutions" title="Click to Reload"/>




      

      


      <!-- <img width="50%" height="auto" class="applogo" src="assets/AlchemedPweredByiHealth.png" loading="lazy" *ngIf="(!isAlchemintHosted) && (isExternalRequest===false) && (winAppIntegratedMode===false) && (isIntegratedBillingEnabled===true)" (click)="reloadPageOrStopRecording()"  alt="Alchemed Solutions" title="Click to Reload"/> -->

      <!-- <img style="width:30vw; height: auto; max-width:20cm;" class="applogo" src="assets/AlchemedPoweredByBiotek.png" loading="lazy" *ngIf="(!isAlchemintHosted) && (isExternalRequest===false) && (winAppIntegratedMode===false) && (isConferenceOrgBiotech===true)" (click)="reloadPageOrStopRecording()"  alt="Alchemed Solutions" title="Click to Reload"/> -->



      <app-in-line-assistance *ngIf="(isSnapin === false) && (showWhatsNew === true) && (winAppIntegratedMode===false)" class="applogo" [flagAsNewFeature]="true" [flagAsNewStyling]="'font-size: small;   transform: rotate(340deg);'" [flagAsNewColor]="'#8eba13'" [flagAsNewText]="'What\'s New 🎯'" [flagAsNewHyperlink]="'https://sys.alchemed.com/app/index.html#/content/2a2ea1ca-0de9-499d-ba39-72ced2ff3de9/WN.EWHATSNEW.VER4'"></app-in-line-assistance>

      <img width="50%" height="50%" src="assets/Alchemednew.png" loading="lazy" *ngIf="(!isAlchemintHosted) && (isExternalRequest===true)" (click)="reloadPageOrStopRecording()"  alt="Alchemed Solutions" />


      <app-in-line-assistance [hidden]="userAssistanceService.inUserAssistanceMode===false" [uiItemIdentifier]="'APP.Reload'" [toolTipIconVisible]="true" [iconColor]="userAssistanceService.userAssistanceModeIconColors"></app-in-line-assistance>


      <!-- <img [hidden]="progressService?.isBusy===false" width="50%" height="50%" src="assets/Alchemednew-busy.png" *ngIf="!isAlchemintHosted" (click)="checkVersion()"         alt="Alchemed Solutions" /> -->

      <!-- <a [hidden]="progressService?.isBusy===false" ><div class="spinner-border spinner-border-sm"></div></a> -->


      <!-- <a [hidden]="routing===false" ><div style="color: yellow;" class="spinner-border spinner-border-sm"></div></a> -->

      <div [hidden]="routing===false" id="alchemed_app_loader_id" class="loader">


      </div>

      

      <label *ngIf="appUpdateService.updating" style="color: darkorange;">
        Updating ...
      </label>

      <label *ngIf="authenticationService?.reloggingInWithRefreshToken==true" style="color: darkorange;">
        Re-authenticating....
      </label>

      <!-- <label *ngIf="loadedComponentName" style="color: darkorange;">
        {{loadedComponentName}}
      </label> -->

      <label [appNonMobileFeature]="true"  *ngIf="loadedComponentNameObservable" class="alchemed-primary" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode">
        <!-- <i style="margin-right: 1em;">{{orgNameObservable$ | async}}</i> -->

        <i *ngIf="(isConferenceOrgBiotech===false)" blurElement [blurOn]="blurOn" style="margin-right: 1em;">{{envService.orgNameBehaveSubject$ | async}}</i>

        <span *ngIf="isSnapin === true">


            <b>{{loadedComponentNameObservable | async}}</b>

          </span>
        <span *ngIf="isSnapin === false">
          <b>{{loadedComponentName}}</b>
        </span>

      </label>

      <div style="color:orangered;" *ngIf="eSpecialFunctionalOrganizationsType!==0">
        {{eSpecialFunctionalOrganizationsTypeName(eSpecialFunctionalOrganizationsType)}}
      </div>


      <!-- <mat-progress-bar *ngIf="progressService?.isBusy===true" ></mat-progress-bar> -->

    </div>


    <!-- <label *ngIf="progressService?.isBusy===true" style="color: darkorange;">
      Busy....
    </label> -->

    <!-- <ul class="nav navbar-nav navbar-right" style="float: right;" [hidden]="mustHideTitleBar === false">
          <app-svg (click)="toggleFullScreenMode()" title="Fullscreen">fullscreen</app-svg >
    </ul> -->

    <ul class="navbar-top-right" [hidden]="(mustHideTitleBar === false) || (mustHideFullScreenButton === true)">
      <app-svg (click)="toggleFullScreenMode()" title="Exit Full Screen">close_fullscreen</app-svg>
    </ul>
    <ul class="nav navbar-nav navbar-right" [hidden]="mustHideTitleBar === true">





      <!-- <mat-slide-toggle  class="alc_settings_switch" [ngModel]="useDocker" (change)="setOrgSetting(orgset.name, $event)"> {{orgset.description}} </mat-slide-toggle>  -->
      <div *ngIf="runningInDevMode" [appNonMobileFeature]="true">
        <mat-slide-toggle class="alc_settings_switch" [ngModel]="useDocker" (change)="setuseDockerSetting($event)"> Use Docker </mat-slide-toggle>
          {{getDomZize()}}
          {{hostName}}
      </div>

      <a  [appNonMobileFeature]="true" class="nav-item nav-link" *ngIf="isBetaVersion==true" style="color: red;">BETA</a>

      <a [appNonMobileFeature]="true" class="nav-item nav-link" *ngIf="showEnvironmentName==true" style="color: red;">ENV: {{ environmentName() }}</a>
      <a [appNonMobileFeature]="true" class="nav-item nav-link" *ngIf="showHttpErrorCount==true" style="color: red;">HTTP ERROR COUNT: {{httpErrorCount}}</a>






      <a class="nav-item nav-link" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode" *ngIf="(!isAlchemintHosted) && (showHome==true)" activity-track id="home" routerLink="/cp">HOME</a>

      <a class="nav-item nav-link" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode" *ngIf="isAlchemintHosted" routerLink="/">HOME</a>



      <a class="nav-item nav-link"  [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode" *ngIf="showBack==true" (click)="back()">BACK</a>





      <app-popup-menu  *ngIf="(isLoggedIn) && (isIntegratedBillingEnabled===false)" style="margin: 0px; padding : 0px;" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode">
        <a popup-trigger  menu  style="padding:0px; margin:0px;" style="color: ghostwhite; " class="nav-item nav-link">
          BILLING <app-in-line-assistance [uiItemIdentifier]="'FEATURE.BILLING'"></app-in-line-assistance>
        </a>
          <ng-container popup-options class="popup-options" >

            <!-- <app-popup-menu-item (click)="gotoInvoicing()" > Invoicing <app-in-line-assistance [uiItemIdentifier]="'FEATURE.INVOICING'"></app-in-line-assistance></app-popup-menu-item> -->
            <app-popup-menu-item [hidden]="isBillingEnabled===false" (click)="gotoReceipting()" > Receipting <app-in-line-assistance [uiItemIdentifier]="'FEATURE.RECEIPTING'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item [hidden]="isBillingEnabled===false" (click)="gotoDebtorsLedger()" > Debtors Ledger <app-in-line-assistance [uiItemIdentifier]="'FEATURE.DEBTORSLEDGER'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item [hidden]="isBillingEnabled===false" (click)="gotoDebtorsLedgerSummary()" > Debtors Ledger Summary <app-in-line-assistance [uiItemIdentifier]="'FEATURE.DEBTORSLEDGER'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item [hidden]="isBillingEnabled===false" (click)="gotoStatements()" > Statements <app-in-line-assistance [uiItemIdentifier]="'FEATURE.DEBTORSLEDGER'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item [hidden]="isBillingEnabled===false" (click)="gotoCreditNotes()" > Credit Noting <app-in-line-assistance [uiItemIdentifier]="'FEATURE.CREDITNOTES'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item [hidden]="isBillingEnabled===false" (click)="gotoReAllocatePayment()" > Re-Allocate Payments <app-in-line-assistance [uiItemIdentifier]="'FEATURE.CREDITNOTES'"></app-in-line-assistance></app-popup-menu-item>
            <!-- <app-popup-menu-item [hidden]="isBillingEnabled===false" routerLink="/my-reports/daily-turnover"> Daily Turnover Report <app-in-line-assistance [uiItemIdentifier]="'FEATURE.DAILYTURNOVER'"> </app-in-line-assistance>  </app-popup-menu-item> -->


            <app-popup-menu-item [hidden]="isBillingEnabled===false" routerLink="/reporting/REP/Invoices"> Invoices Report <app-in-line-assistance [uiItemIdentifier]="'FEATURE.REPORTS.INVOICES'"> </app-in-line-assistance> </app-popup-menu-item>
            <app-popup-menu-item [hidden]="isBillingEnabled===false" routerLink="/reporting/REP/InvoiceDetail"> Invoice Detail Report <app-in-line-assistance [uiItemIdentifier]="'FEATURE.REPORTS.RECEIPTING'"> </app-in-line-assistance> </app-popup-menu-item>

            <app-popup-menu-item [hidden]="isBillingEnabled===false" routerLink="/reporting/REP/CreditNotes"> Credit Notes Report <app-in-line-assistance [uiItemIdentifier]="'FEATURE.REPORTS.CREDITNOTES'"> </app-in-line-assistance> </app-popup-menu-item>
            <app-popup-menu-item [hidden]="isBillingEnabled===false" routerLink="/reporting/REP/Receipting"> Receipting Report <app-in-line-assistance [uiItemIdentifier]="'FEATURE.REPORTS.RECEIPTING'"> </app-in-line-assistance> </app-popup-menu-item>




            <app-popup-menu-item [hidden]="isBillingEnabled===true" > Please contact Alchemed to Purchase <app-in-line-assistance [uiItemIdentifier]="'FEATURE.CREDITNOTES'"></app-in-line-assistance></app-popup-menu-item>

          </ng-container>
      </app-popup-menu>

      <app-popup-menu  *ngIf="(isLoggedIn) && (uiConfig.modules===true)" style="margin: 0px; padding : 0px;" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode">
        <a popup-trigger  menu  style="padding:0px; margin:0px;" style="color: ghostwhite; " class="nav-item nav-link">
          MODULES
        </a>
          <ng-container popup-options class="popup-options" >
            <app-popup-menu-item  [appNonMobileFeature]="true" routerLink="/diary"> Diary</app-popup-menu-item>
            <!-- <app-popup-menu-item  [appNonMobileFeature]="true" routerLink="/videocall"> Video Consultations</app-popup-menu-item> -->

            <!--  -->
            
            <app-popup-menu-item routerLink="learning" > Learning Centre <app-in-line-assistance [uiItemIdentifier]="'FEATURE.DIDYOUKNOW'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item *ngIf="showTraining===true" routerLink="training/CLIN" > Training <app-in-line-assistance [uiItemIdentifier]="'FEATURE.TRAININGMODULE'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item *ngIf="showCommandCentre" routerLink="commandcentre" > Command Center <app-in-line-assistance [uiItemIdentifier]="'FEATURE.ALTRON'"></app-in-line-assistance></app-popup-menu-item>
            
            <app-popup-menu-item routerLink="/form-builder"  [appNonMobileFeature]="true" > Form Builder <app-in-line-assistance [uiItemIdentifier]="'FEATURE.FORMBUILDER'" > </app-in-line-assistance> </app-popup-menu-item>
            <app-popup-menu-item routerLink="/formlib"  [appNonMobileFeature]="true" > Form Library <app-in-line-assistance [uiItemIdentifier]="'FEATURE.FORMLIBRARY'" > </app-in-line-assistance> </app-popup-menu-item>




            <app-popup-menu-item routerLink="/reporting/REP/NONE" [appNonMobileFeature]="true" > Reporting <app-in-line-assistance [uiItemIdentifier]="'FEATURE.REPORTING'"> </app-in-line-assistance>  </app-popup-menu-item>
            <app-popup-menu-item routerLink="/reporting/TOOLS/NONE" [appNonMobileFeature]="true" > Tools <app-in-line-assistance [uiItemIdentifier]="'FEATURE.TOOLS'"> </app-in-line-assistance> </app-popup-menu-item>
            <app-popup-menu-item routerLink="/reporting/FORMS/NONE" [appNonMobileFeature]="true" > Form Reports <app-in-line-assistance [uiItemIdentifier]="'FEATURE.FORMREPORTS'"> </app-in-line-assistance> </app-popup-menu-item>

            <!-- <app-popup-menu-item *ngIf="isBillingEnabled" (click)="gotoAdminControlPanel()" > Billing <app-in-line-assistance [uiItemIdentifier]="'FEATURE.BILLINGMODULE'"></app-in-line-assistance></app-popup-menu-item> -->

            <app-popup-menu-item routerLink="/rehab/general"> Rehab Programme Builder <app-in-line-assistance [uiItemIdentifier]="'FEATURE.REHABPROGBUILDER'"> </app-in-line-assistance> </app-popup-menu-item>


            <app-popup-menu-item (click)="hideDropDown(); launchAdvancedSearch();" >  Advanced Search <app-in-line-assistance [uiItemIdentifier]="'FEATURE.ADVANCEDSEARCH'" ></app-in-line-assistance>  </app-popup-menu-item>
            <!-- <app-popup-menu-item *ngIf="dataLakeLicensed===false" (click)="hideDropDown(); launchAdvancedSearch();" >  Advanced Search <app-in-line-assistance [uiItemIdentifier]="'FEATURE.ADVANCEDSEARCHNOTENABLED'" ></app-in-line-assistance>  </app-popup-menu-item> -->



            <app-popup-menu-item routerLink="/di/0/0" [appAlphaFeature]="true"> Consult Module <app-in-line-assistance [uiItemIdentifier]="'FEATURE.CONSULTMODULE'"> </app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item routerLink="/di/1/0" > Inpatients Module <app-in-line-assistance [uiItemIdentifier]="'FEATURE.INPATIENTMODULE'"> </app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item routerLink="/di/4/0"> Discharged Patients <app-in-line-assistance [uiItemIdentifier]="'FEATURE.DISCHARGEDPATIENTS'"> </app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item [appAlphaFeature]="true" routerLink="surgicaldashboard"> Surgical Dashboard <app-in-line-assistance [uiItemIdentifier]="'FEATURE.DISCHARGEDPATIENTS'"> </app-in-line-assistance></app-popup-menu-item>



            <app-popup-menu-item routerLink="/di/0/1" > Admin Notes Module <app-in-line-assistance [uiItemIdentifier]="'FEATURE.ADMINNOTESMODULE'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item [appBetaFeature]="true" routerLink="askalchemed/specialmode/4" > Ask Alchemed <app-in-line-assistance [uiItemIdentifier]="'FEATURE.ASKDRWILL'"></app-in-line-assistance></app-popup-menu-item>

            
          
            <app-popup-menu-item routerLink="/reporting/REP/ArtifactChangeLog" [appNonMobileFeature]="true" > File Change Logs <app-in-line-assistance [uiItemIdentifier]="'FEATURE.FILECHANGELOG'"></app-in-line-assistance></app-popup-menu-item>

            <app-popup-menu-item routerLink="/vital-signs" [appNonMobileFeature]="true" > Vital Signs <app-in-line-assistance [uiItemIdentifier]="'FEATURE.VITALSIGNS'"></app-in-line-assistance></app-popup-menu-item>

            <app-popup-menu-item  (click)="launchTaskManagerForUser()" [appAlphaFeature]="true" > Task Management <app-in-line-assistance [uiItemIdentifier]="'FEATURE.TASKMANAGEMENT'"></app-in-line-assistance></app-popup-menu-item>

          </ng-container>
      </app-popup-menu>

      <app-popup-menu  *ngIf="shoDemoTools" style="margin: 0px; padding : 0px;" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode">
        <a popup-trigger  menu  style="padding:0px; margin:0px;" style="color: ghostwhite; " class="nav-item nav-link">
          DEMO TOOLS
        </a>
        <ng-container popup-options class="popup-options" >
          <app-popup-menu-item  [appNonMobileFeature]="true" routerLink="/demo-tools"> Data Management</app-popup-menu-item>
          <app-popup-menu-item  [appNonMobileFeature]="true" routerLink="/timesaver/0"> Saver Calculator for Clinician</app-popup-menu-item>
          <app-popup-menu-item  [appNonMobileFeature]="true" routerLink="/timesaver/1"> Saver Calculator for PM</app-popup-menu-item>

        </ng-container>
      </app-popup-menu>


      <app-popup-menu  *ngIf="(isLoggedIn) && (uiConfig.modules===true)" style="margin: 0px; padding : 0px;" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode">
        <a popup-trigger  menu  style="padding:0px; margin:0px;" style="color: ghostwhite; " class="nav-item nav-link">
          {{getConfigMenuText()}}
        </a>
          <ng-container popup-options class="popup-options" >
            <app-popup-menu-item routerLink="/org"> Manage Organization <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGEORGANIZATION'"></app-in-line-assistance> </app-popup-menu-item>
            <app-popup-menu-item *ngIf="canManageUsers" routerLink="/user-management" [appNonMobileFeature]="true" > Manage Users <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGEUSERS'"> </app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item *ngIf="(canManageUsers===true) && (isIntegratedBillingEnabled===true)" (click)="synchronizeIntegratedUsers()" > Synchronize Integrated Users <app-in-line-assistance [uiItemIdentifier]="'FEATURE.SYNCINTEGRATEDUSERS'"></app-in-line-assistance></app-popup-menu-item>

            <app-popup-menu-item routerLink="/contracts" [appNonMobileFeature]="true" > Manage Contracts <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGECONTRACTS'" ></app-in-line-assistance></app-popup-menu-item>


            <app-popup-menu-item routerLink="/templates" [appNonMobileFeature]="true" > Manage Templates <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGETEMPLATES'"></app-in-line-assistance></app-popup-menu-item>

            <app-popup-menu-item routerLink="/manage-folders" [appNonMobileFeature]="true" > Manage Folders <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGEFOLDERS'"> </app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item routerLink="/consult-types"> Manage Consult Types <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGECONSULTTYPES'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item *ngIf="isIntegratedBillingEnabled===false" routerLink="/sites"> Manage Sites <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGESITES'"></app-in-line-assistance></app-popup-menu-item>

            <app-popup-menu-item [appNonMobileFeature]="true" routerLink="/icd"> Manage ICD-10 Codes <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGEDIAGNOSISCODES'"></app-in-line-assistance> </app-popup-menu-item>
            <app-popup-menu-item [appNonMobileFeature]="true" routerLink="/pcodes"> Manage Procedure Codes <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGEPROCEDURECODES'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item [appNonMobileFeature]="true" routerLink="/pcodes" [queryParams]="{ specialtycode: 72 }">   Manage Physio Procedure Codes <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGEPROCEDURECODES'"></app-in-line-assistance></app-popup-menu-item>
            
            
            
            <app-popup-menu-item [appNonMobileFeature]="true" routerLink="/mcodes"> Manage Material Codes <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGEMATERIALCODES'"></app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item routerLink="/shortcut-keys" [appNonMobileFeature]="true" > Manage Shortcut Keys <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGESHORTCUTKEYS'" > </app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item routerLink="/org-email-settings" > Manage Practice SMTP Settings <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGESMTP'"> </app-in-line-assistance></app-popup-menu-item>

            <app-popup-menu-item routerLink="/configure-diary" *ngIf="showConfigureDiary===true" > Configure Diary <app-in-line-assistance [uiItemIdentifier]="'FEATURE.CONFIGUREDIARY'"> </app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item [appNonMobileFeature]="true" routerLink="/manage-contacts"> Manage Contacts <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGECONTACTS'"> </app-in-line-assistance></app-popup-menu-item>


            <app-popup-menu-item routerLink="/custom-lists" [appNonMobileFeature]="true" > Manage Custom Lists <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGECUSTOMLISTS'"> </app-in-line-assistance></app-popup-menu-item>
            <app-popup-menu-item routerLink="/app-keys" > Manage App Keys <app-in-line-assistance [uiItemIdentifier]="'FEATURE.MANAGEAPPKEYS'"> </app-in-line-assistance></app-popup-menu-item>


            <app-popup-menu-item routerLink="/healthcheck" [appNonMobileFeature]="true" > Analyse Configuration <app-in-line-assistance [uiItemIdentifier]="'FEATURE.ANALYZECONFIG'"></app-in-line-assistance></app-popup-menu-item>

            <app-popup-menu-item routerLink="/patientupload" [appNonMobileFeature]="true" > Batch Patient Upload <app-in-line-assistance [uiItemIdentifier]="'FEATURE.BATCHUPLOAD'"></app-in-line-assistance></app-popup-menu-item>

            <app-popup-menu-item [appBetaFeature]="true" (click)="configureMFA()"> Configure 2-Factor Authentication <app-in-line-assistance [uiItemIdentifier]="'FEATURE.CONFIGURE2FA'"></app-in-line-assistance></app-popup-menu-item>

          </ng-container>
      </app-popup-menu>




      <a class="nav-item nav-link" *ngIf="(isLoggedIn==false) && (showLogin==true)" routerLink="login">LOGIN</a>

      <a class="nav-item nav-link" *ngIf="isReadOnlyLogin" style="color: red;">READ ONLY ACCESS</a>



      <svg-icon *ngIf="debugSupportComponent===true" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode" src="assets/svgs/notifications_white_24dp.svg" style="color: ghostwhite;" class="material-icons md-18"  title="Notifications"></svg-icon>

      <svg-icon *ngIf="showInAppSupport===true" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode" src="assets/svgs/support_agent_white_24dp.svg" style="color: ghostwhite;" class="material-icons md-18"  title="Support Chat" (click)="openSupportChat()"></svg-icon>
      
      <svg-icon *ngIf="isLoggedIn===true" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode" src="assets/svgs/help_white_24dp.svg" (click)="toggleAssistedMode()" class="custom-svg-icon-orange"  title="Assisted mode allows in-app help and videos to appear within the application. This can be very useful when you are first coming to grips with the Alchemed application. "></svg-icon>

      <!-- style="margin: 0px; padding : 0px;" -->
      <app-popup-menu  *ngIf="(isLoggedIn)" [hidden]="this.controlPanelComponent?.newMobileModeOptions?.mobileReducedMode">
        
        <!-- class="nav-item nav-link" -->
        <!-- <a popup-trigger  menu  style="padding:0px; margin:0px;" style="color: ghostwhite; " >

          <svg-icon src="assets/svgs/person_white_24dp.svg" style="color: ghostwhite;" class="material-icons md-18"  [title]="loggedInUserName2"></svg-icon>

        </a> -->
        <svg-icon popup-trigger  menu src="assets/svgs/person_white_24dp.svg" style="color: ghostwhite;" class="material-icons md-18"  [title]="loggedInUserName2"></svg-icon>
          <ng-container popup-options class="popup-options" >
            <app-popup-menu-item  (click)="logout()"> Logout {{loggedInUserName2}}</app-popup-menu-item>
            <app-popup-menu-item *ngIf="isLoggedInToBackOffice===true" (click)="logoutBackOffice()"> Logout of Backoffice</app-popup-menu-item>
            
            <app-popup-menu-item  (click)="gotoSettings()"> Settings</app-popup-menu-item>

            <app-popup-menu-item *ngIf="showConsoleLogVisible===true"  (click)="showConsoleLog()"> View Console Log </app-popup-menu-item>
            <app-popup-menu-item (click)="buyMessageBundle()"> Top up Pay-As-You-Go Balance </app-popup-menu-item>
            <app-popup-menu-item (click)="toggleFullScreenMode()"> Full Screen </app-popup-menu-item>

            <app-popup-menu-item (click)="voiceCommandMode()"> Voice Command Mode </app-popup-menu-item>

        </ng-container>
      </app-popup-menu>




    </ul>
  </div>
</nav>

<div class="alc_update_div system-version-updates" *ngIf="(updateRequired==true) && (useDocker == false)" [hidden]="mustHideTitleBar === true">


  <!-- <a class="nav-item nav-link" *ngIf="updateRequired==true" style="color: red;">UPDATE REQUIRED</a> -->
  <label style="padding-right: 2em; color: red;">Update Required</label>

  <label style="padding-right: 2em;">Client version : {{version}}</label>
  <button class="btn btn-primary" style="margin-right: 2em;" (click)="updateClient()">Update</button>
  <button class="btn btn-primary" (click)="checkServerVersion()">Check Server Version</button>
</div>



<div [class]="mustHideTitleBar ? '' : 'container-fluid'" [hidden]="routing" >
  <router-outlet *ngIf="!showSideNav" (activate)="componentAdded($event)"></router-outlet>
</div>





<fd-alert [timeOut]="6000"></fd-alert>


<!-- 
<button (click)="simulateUpdateRequired()">
  Chedck {{appUpdateService.versionCheckComplete}}
</button> -->
<!-- <label>
  Version: {{appUpdateService.currentVersion}}
</label> -->

<div *ngIf="showSupportChat">
  <app-support-chat [uniqueUserId]="loggedInUserId" [masterHelpDeskMode]="false" (requestCloseChat)="showSupportChat=false">
  
  </app-support-chat>
  
</div>